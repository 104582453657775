<script setup lang="ts">

import { useSlots } from 'vue';
const slots = useSlots();

const props = defineProps<{
  title?: string;
}>();

</script>

<template>
  <section class="o-block">
    <header class="o-block__header">
      <h4 v:if="title" class="o-block__title">
        {{ title }}
      </h4>
      <div class="o-block__cta" v-if="slots.blockLink">
        <slot name="blockLink"></slot>
      </div>
    </header>
    <div class="o-block__content">
      <slot></slot>
    </div>
  </section>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.o-block {
  $this: &;
  background-color: var(--g-color-gray-10);
  margin: var(--g-widget-y-spacing);
  padding: var(--g-widget-spacing);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .8rem;
    margin-bottom: 1.6rem;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 5.4rem;
      height: .4rem;
      background: var(--g-gradient-primary-secondary);
    }
  }

  &__title {
    @include m.h4-bold;
    text-transform: uppercase;
    margin-top: 1.2rem;
  }
}
</style>
