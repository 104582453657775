<script setup lang="ts">
import Article from '@/lib/article'

interface InputProps {
    article: ArticleType | PodcastType;
    primary?: boolean;
    showExcerpt?: boolean;
    modifierClass?: string;
    articleType?: string;
}

const props = withDefaults(defineProps<InputProps>(), {
    primary: false,
    showExcerpt: true,
    modifierClass: '',
    articleType: 'articles',
})

const article: Article = new Article(props.article)
</script>

<template>
    <a :href="'/' + articleType + '/' + article.slug + '/' + article.id" class="o-article-card" :class="modifierClass">
        <div v-bind:id="'featured-' + article.id" class="o-article-card__image-container">
            <img :src="article.getImagePath('medium')" :alt="article.title" loading="lazy" class="o-article-card__image" />
        </div>
        <div class="o-article-card__main">
            <h2 class="o-article-card__title">
                {{ article.title }}
            </h2>
            <p><span v-for="(contributor, index) in article.contributors">By {{
                    contributor?.Name ?? 'BRFCS.com'
                }}</span></p>
            <p v-if="showExcerpt" class="o-article-card__excerpt">
                {{ article.excerpt }}
            </p>
            <span class="o-article-card__meta">
        {{ article.published_at }}
      </span>
        </div>
    </a>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.o-article-card {
    $this: &;
    border-radius: .8rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include m.link-reset;
    width: 23%;
    margin: 0 1% 2vw;
    aspect-ratio: 7/9;
    transition: box-shadow ease-out 0.2s;
    background-color: var(--g-color-system-white);
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -13rem;
        right: -12rem;
        width: 30rem;
        height: 30rem;
        background-image: url('/images/logos/blue-rose.png');
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.05;
        transition: transform ease-out 0.2s;
    }

    @include m.hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, .1), 0 10px 10px rgba(0, 0, 0, .1);

        #{$this}__image {
            transform: scale(1.05);
        }

        #{$this}__main {
            &::before {
                width: 8rem;
            }
        }

        &::after {
            transform: scale(1.05);
        }
    }

    &--featured-item {
        width: 48%;
        flex-direction: row;
        aspect-ratio: 16/9;

        #{$this}__image-container,
        #{$this}__main {
            flex: 1 50%;
            aspect-ratio: auto;
        }

        #{$this}__main {
            &::after {
                bottom: -10rem;
                right: -10rem;
                width: 40rem;
                height: 40rem;
            }
        }

        #{$this}__title {
            @include m.truncate-multiline(3, var(--h4-line-height));
        }
    }

    &__image-container {
        position: relative;
        aspect-ratio: 16/9;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform ease-out 1s;
    }

    &__main {
        padding: 3.4rem 1.6rem 1.6rem;
        flex: 1 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1.2rem;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 2rem;
            left: 1.6rem;
            width: 4rem;
            height: .2rem;
            background: var(--g-gradient-primary-secondary);
            transition: width ease-out 0.2s;
        }
    }

    &__title {
        @include m.h4-bold;
        text-transform: uppercase;
        @include m.truncate-multiline(2, var(--h4-line-height));
    }

    &__excerpt {
        @include m.sm;
        @include m.truncate-multiline(3, var(--sm-line-height));
    }

    &__meta {
        margin-top: auto;
        @include m.sm;
    }

    @media (width < m.$desktop) {
        width: 31%;

        &--featured-item {
            width: 31%;
            aspect-ratio: 7/9;
            flex-direction: column;

            #{$this}__image-container,
            #{$this}__main {
                flex: 1 100%;
                aspect-ratio: auto;
            }

            #{$this}__image-container {
                aspect-ratio: 16/9;
            }
        }
    }

    @media (width < m.$tablet) {
        width: 48%;

        &__main {
            padding: 2rem 1.2rem 1.2rem;

            &::before {
                top: 1.2rem;
                left: 1.2rem;
            }
        }

        &--featured-item {
            width: 48%;
        }
    }

    @media (width < m.$phablet) {
        width: 100%;
        flex-direction: row;
        aspect-ratio: auto;

        &--featured-item {
            width: 100%;

            #{$this}__image-container {
                flex: 1 0 auto;
                aspect-ratio: auto;
            }
        }

        &__image-container {
            width: 10rem;
        }
    }
}
</style>
